@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap');

*{
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    font-family: 'Poppins', sans-serif;
}

:root {
    --PrimaryColor: hsl(199, 100%, 33%);
    --SecondaryColor: hsl(187, 85%, 43%);
    --gradientColor: linear-gradient(to right, hsl(187, 85%, 43%),hsl(199, 100%, 33%));
    --whiteColor: hsl(0, 0%, 100%);
    --blackColor: hsl(200, 42%, 62%);
    --textColor: hsl(240, 4%, 36%);
    --whiteColorDeam: hsl(0, 0%, 93%);
    --greyText: rgb(190, 190, 190);
    --inputColor: rgb(239, 239, 239);
  }
  
  .header {
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: var(--whiteColorDeam);
    width: 100%;
    height: var(--headerHeight);
    z-index: 1000;
    padding: 1rem;
  }
  .header .logo {
    color: var(--blackColor);
    font-weight: 600;
    cursor: pointer;
    align-items: center;
    justify-content: center;
  }
  .header .logo .icon {
    font-size: 25px;
    color: var(--PrimaryColor);
    transform: translateY(5px);
  }
  @media screen and (max-width: 768px) {
    .header .navBar {
      background: var(--whiteColorDeam);
      position: absolute;
      height: -webkit-max-content;
      height: -moz-max-content;
      height: max-content;
      width: 80%;
      border-radius: 1rem;
      padding: 1rem;
      top: -700%;
      left: 50%;
      transform: translate(-50%);
      z-index: 2000;
      box-shadow: 0 2px 4px rgba(2, 15, 29, 0.904);
      transition: 0.3s ease-in-out;
    }
    .header .navBar .navLists {
      display: flex;
      flex-direction: column;
      justify-content: center;
      width: 100%;
      align-items: center;
      margin: auto;
      padding: 0.5rem 0;
    }
    .header .navBar .navLists .navItem {
      padding: 0.5rem 0;
    }
    .header .navBar .navLists .navItem .navLink {
      color: var(--textColor);
      font-size: 0.9rem;
      font-weight: 600;
    }
    .header .navBar .navLists .navItem .navLink:hover {
      color: var(--PrimaryColor);
    }
    .header .navBar .navLists .btn {
      margin-top: 1rem;
    }
    .header .navBar .navLists .btn a {
      font-weight: 600;
      color: var(--whiteColor);
    }
    .header .navBar .closeNavbar {
      position: absolute;
      top: 1rem;
      right: 1.5rem;
      color: var(--PrimaryColor);
    }
    .header .navBar .closeNavbar:hover .icon {
      color: var(--SecondaryColor);
    }
    .header .activeNavbar {
      top: 7rem;
    }
  }
  .header .toggleNavbar .icon {
    font-size: 25px;
    color: var(--PrimaryColor);
  }
  .header .toggleNavbar:hover.icon {
    color: var(--SecondaryColor);
  }
  
  .activeHeader {
    background: var(--projectCardColor);
    box-shadow: 0 2px 4px rgba(2, 15, 29, 0.904);
  }
  
  @media screen and (min-width: 769px) {
    .toggleNavbar, .closeNavbar {
      display: none;
    }
    .header {
      padding: 1.5rem 2rem;
    }
    .header .navBar .navLists {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: row;
    }
    .header .navBar .navLists .navItem .navLink {
      color: var(--textColor);
      padding: 0 0.45rem;
      font-size: 0.9rem;
      font-weight: 500;
    }
    .header .navBar .navLists .navItem .navLink:hover {
      color: var(--PrimaryColor);
    }
    .header .navBar .navLists .navItem .navLink .headerNumber {
      color: var(--primaryHover);
    }
    .header .navBar .navLists .btn {
      margin-left: 1rem;
    }
    .header .navBar .navLists .btn a {
      color: var(--whiteColor);
      font-weight: 500;
    }
  }
  @media screen and (min-width: 960px) {
    .header .navBar .navLists .navItem {
      padding: 0 0.7rem;
    }
  }
  @media screen and (min-width: 1024px) {
    .header {
      padding: 1rem 8.5rem;
    }
  }

  
  .input-bayi{
    border: none;
    background-color: rgb(243, 240, 240);
    height: 2em;
    width:100%;
    border-radius: 1em;
    text-align: center;
    padding: 2em;
    border: 1px solid black;
    
  }
  
.bilesenler {
  display: flex;
  justify-content: space-between;
  margin: 0 auto;
  width: 60%;
}

.bilesen-bayiOlustur, .bilesen-bayilerim {
  background-color: #e9e9e9;
  padding: 20px;
  border-radius: 2rem;
  cursor: pointer;
  width: 80%;
  margin-right: 5%;
  font-weight: bold;
  
}

.bilesen-bayiOlustur:hover {
 
 
  background-color: #f9f92d;
  font-size: larger;
 
}

.bilesen-bayilerim:hover{
  background-color: #f9f92d;
  font-size: larger;
}

.dukkanlarim{
  flex-direction: row;
  align-items: center;
  background-color: #e9e9e9;
  padding: 20px;
  border-radius: 2rem;
  cursor: pointer;
  width: 30%;
  font-weight: bold;
  margin-bottom: 1%;
  height: 80px;
}

.dukkanlarim:hover{
  background-color: #f9f92d;
  font-size: larger;
 
}

.dukkanlistesi{
  margin-top: 1%;
  align-items: center;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  list-style: none;
  padding: 0;
  
}




.dukkanlarim a {
  text-decoration: none;
}
.dukkan-detay-butonlar {
  display: flex;
  justify-content: center;
  gap: 10px;
}

.btn-ddb{
  align-items: center;
  text-align: center;
  background-color: #e9e9e9;
  border-radius: 2rem;
  cursor: pointer;
  width: 15rem;
  font-weight: bold;
  margin-bottom: 3%;
  height: 40px;
}

.btn-ddb:hover{
  background-color: #f9f92d;
 
}
footer {
  position: fixed;
  background-color: hsl(0, 0%, 92%);
  bottom: 0;
  left: 0;
  right: 0;
  padding: 8px;
  text-align: center;
}
/* .bayi-urunEkleme-butonlar{
  display: flex;
  justify-content: center;
  gap: 10px;
}
.bayi-urunEklemeButonu{
  align-items: center;
  text-align: center;
  background-color: #e9e9e9;
  border-radius: 2rem;
  cursor: pointer;
  width: 10%;
  font-weight: bold;
  margin-bottom: 1%;
  height: 50px;
}
.bayi-urunEklemeButonu:hover{
  background-color: #f9f92d;
  font-size: larger;
}
.dukkandetay{
  
  justify-content: center;
  align-items: center;
}


.bayi-urunKaydetmeButonu{
  align-items: center;
  text-align: center;
  background-color: #e9e9e9;
  border-radius: 2rem;
  cursor: pointer;
  width: 5%;
  font-weight: bold;
  margin-bottom: 1%;
  height: 50px;
}
.bayi-urunKaydetmeButonu:hover{
  background-color: #f9f92d;
  font-size: larger;
} */



/* Bayi.css */

/* ... */

.main {
  /* Genel düzenleme */
  display: flex;
  flex-direction: column;
  align-items: center;

    justify-content: center;
    text-align: center;
    
}

.dukkandetay {
  /* Bayi detayları */
  width: 80%;
  max-width: 600px; /* Maksimum genişlik sınırlaması */
  margin: 0 auto;
  padding: 20px;
  background-color: white;
  border-radius: 10px;
  text-align: center;
  flex:1;
}

.bayi-urunEkleme {
  /* Bayi ürünleri */
  width: 80%;
  max-width: 700px; /* Maksimum genişlik sınırlaması */
  margin: 0 auto;
  padding: 20px;
  background-color: white;
  border-radius: 10px;
  text-align: center;
  flex:1;
}

.bayi-urunEkleme .product-list {
  /* Ürün listesi */
  list-style: none;
  padding: 0;
  height: 500px;
  overflow: auto;
}



.bayi-urunEklemeButonu{
  align-items: center;
  text-align: center;
  background-color: #e9e9e9;
  border-radius: 2rem;
  cursor: pointer;
 margin-right: 2%;
  font-weight: bold;
  margin-bottom: 1%;
  height: 50px;
  width: 30%;
}

.bayi-urunEkleme .product-item {
  /* Ürün öğeleri */
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 10px;
  margin-bottom: 10px;
  text-align: left;
}

.bayi-urunEkleme .product-item p {
  margin: 0;
}

.bayi-urunEkleme .bayi-urunEkleme-butonlar {
  /* Butonlar */
  display: flex;
  justify-content: center;
  margin-top: 20px;
  gap: 20px;
}



.modal {
  /* Modal */
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
}

.modal-content {
  /* Modal içeriği */
  background-color: #f1f1f1;
  border-radius: 10px;
  padding: 20px;
  text-align: center;
  width: max-content;
}


.urunEkleme-input{
  align-items: center;
  text-align: center;
  background-color: #e9e9e9;
  border-radius: 2rem;
  cursor: pointer;
  font-weight: bold;
  margin-bottom: 1%;
  width: 100%;

}

.urunArama-input{
  align-items: center;
  text-align: center;
  background-color: #e9e9e9;
  border-radius: 2rem;
  cursor: pointer;
 margin-right: 2%;
  font-weight: bold;
  margin-bottom: 1%;
  height: 50px;
  width: 60%;
}
.bayi-urunEklemeButonu:hover{
  background-color: #f9f92d;
  font-size: larger;
}
.bayi-urunKaydetmeButonu{
  align-items: center;
  text-align: center;
  background-color: #e9e9e9;
  border-radius: 2rem;
  cursor: pointer;
  font-weight: bold;
  margin-bottom: 1%;
  height: 50px;
  width: 40%;

}
.bayi-urunKaydetmeButonu:hover{
  background-color: #f9f92d;
  font-size: larger;
}
.bayi-urunEkleme-butonlar{
  display: flex;
  justify-content: center;
  gap: 20px;
}

.bayi-urunDüzenleButonu{
  align-items: center;
  text-align: center;
  background-color: #e9e9e9;
  border-radius: 2rem;
  cursor: pointer;
  font-weight: bold;
  margin-bottom: 1%;
  height: 30px;
  width: 100px;
  margin-right: 2%;
  margin-top: 2%;

}
.bayi-urunDüzenleButonu:hover{
  background-color: #f9f92d;
  
}

.mainShopdetails{
  display: flex;
  flex-direction: row;
}
@media (max-width: 767px) {
  .main {
    flex-direction: column;
  }
}
@media (max-width: 767px) {
  .mainShopdetails {
    flex-direction: column;
  }
}

.siparislerimContainer ul li:not(:last-child) {
  border-bottom: 1px solid #ccc;
  padding-bottom: 10px;
  margin-bottom: 10px;
}

.siparislerimContainer {
  margin-top: 20px;
  overflow: auto; /* Scroll özelliği */
  max-height: 300px; /* Maksimum yükseklik */
  max-width:100%;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin-bottom: 100px;

}
.siparislerim {
  font-size: 16px;

  margin-bottom: 200px;
  border: 1px solid black;
  overflow: auto; /* Scroll özelliği */
  max-height: 600px; /* Maksimum yükseklik */
  max-width:100%;
}

.siparislerimContainer ul {
  list-style: none;
  padding: 0;
 
  
}

.siparislerimContainer ul li:not(:last-child) {
  border-bottom: 1px solid #ccc;
  padding-bottom: 10px;
  margin-bottom: 20px;
 
}

.siparislerimContainer ul li div {
  margin-bottom: 20px;
  font-size: 12;
}
.modal-content form div label {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.modal-content form div label input[type="radio"] {
  flex-shrink: 0;
  margin-right: 8px;
}

.modal-content form div label span {
  flex-grow: 1;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-content {
  background-color: white;
  padding: 20px;
  border-radius: 5px;
}

.modal h2 {
  text-align: center;
  margin-bottom: 10px;
}

.modal form {
  display: flex;
  flex-direction: column;
}

.modal form div {
  margin-bottom: 10px;
}

.modal label {
  margin-left: 5px;
}

.modal button {
  padding: 8px 16px;
  margin-right: 5px;
  cursor: pointer;
}

.modal button[type="button"] {
  background-color: #000000;
  color: white;
}

.modal button[type="submit"] {
  background-color: #4caf50;
  color: white;
}
.update-button {
  background-color: rgb(18, 18, 18);
  color: white;
  border: none;
  padding: 8px 16px;
  cursor: pointer;
  border-radius: 5px;
  margin-bottom: 20px;
  margin-left: 20px;
  margin-top: 20px;
}

.update-button:hover {
  background-color: #fcf567;
  color: rgb(0, 0, 0);
}

.update-button:focus {
  outline: none;
}

.update-button:active {
  background-color: #0c589d;
}


.modal-content form div label input[type="radio"] {
  transform: scale(0.5);
  margin-right: 8px;
}

.modal-warning {
  font-size: 14px;
  color: red;
  margin-top: 10px;
  text-align: center;
}

/* Modal */
.modal {
  position: fixed; /* Sabit konumlandırma */
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7); /* Arkaplan rengi ve opaklık */
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-content {
  background-color: #fff; /* Modal içeriğinin arkaplan rengi */
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Modalın altına hafif bir gölge ekleyerek yükselme efekti */
  max-width: 600px; /* Modalın maksimum genişliği */
  width: 90%; /* Modalın genişliği */
}

.modal h2 {
  margin-top: 0; /* Başlığın üst boşluğunu kaldır */
}

/* Modal Scrollable Content */
.modal-scrollable-content {
  max-height: 500px; /* İçerik bölümünün maksimum yüksekliği */
  overflow-y: auto; /* Dikey kaydırma çubuğunu ekle */
}

.modal-scrollable-content ul {
  list-style: none;
  padding: 0;
}

.modal-scrollable-content li {
  margin-bottom: 10px;
}

/* Kapat Butonu */
.modal button {
  background-color: #f44336; /* Kapat düğmesi arkaplan rengi */
  color: white; /* Kapat düğmesi metin rengi */
  border: none; /* Kenarlık yok */
  padding: 10px 15px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  border-radius: 5px;
  cursor: pointer;
}

/* Kapat Butonu: Üzerine Gelince */
.modal button:hover {
  background-color: #d32f2f;
}

/* Kapat Butonu: Basılı Tutulduğunda */
.modal button:active {
  background-color: #b71c1c;
}
.modal li button {
  background-color: #2196f3; /* Buton arkaplan rengi */
  color: white; /* Buton metin rengi */
  border: none; /* Kenarlık yok */
  padding: 5px 10px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 14px;
  border-radius: 5px;
  cursor: pointer;
}

/* Müşteriyi Değerlendir Butonu: Üzerine Gelince */
.modal li button:hover {
  background-color: #0f8ee5;
}

/* Müşteriyi Değerlendir Butonu: Basılı Tutulduğunda */
.modal li button:active {
  background-color: #0b69c7;
}
/* Düğme stilleri */
.siparis-gecmisi-button {
  background-color: #0e0e0e; /* Düğme arkaplan rengi */
  border: none; /* Kenarlık yok */
  color:white; /* Düğme metni rengi */
  padding: 10px 20px; /* Yükseklik ve genişlik */
  text-align: center; /* Metin merkezde */
  text-decoration: none; /* Altı çizili değil */
  display: inline-block; /* Blok öğe olmayanlarla aynı hizada ol */
  font-size: 16px; /* Metin boyutu */
  cursor: pointer; /* Üzerine gelince imleç şekli */
  border-radius: 5px; /* Köşeleri yuvarla */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Düğmeye hafif bir gölge ekleyerek kabarma efekti */
}

/* Düğme üzerine gelince stil değişikliği */
.siparis-gecmisi-button:hover {
  background-color: #eef53f; /* Düğmenin üzerine gelince arkaplan rengini değiştir */
  color:black;
}

/* Düğme basılı tutulduğunda stil değişikliği */
.siparis-gecmisi-button:active {
  background-color: #367c3d; /* Düğme basılı tutulduğunda arkaplan rengini değiştir */
}

/* Düğme devre dışı kalınca stil değişikliği */
.siparis-gecmisi-button:disabled {
  background-color: #cccccc; /* Devre dışı düğme arkaplan rengi */
  cursor: not-allowed; /* Devre dışı düğme imleç şekli */
}

/* Genel modal stilleri */
.modal-history {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
}

.modal-content-history {
  background-color: #fff;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  max-width: 600px;
  width: 90%;
}

.modal-content-history b h1 {
  font-size: 24px;
  margin-bottom: 20px;
}

.modal-scrollable-content-history {
  max-height: 400px;
  overflow-y: auto;
}

.modal-scrollable-content-history ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.modal-scrollable-content-history li {
  margin-bottom: 20px;
}

.modal-scrollable-content-history p {
  margin: 5px 0;
}

/* Rating modal stilleri */
.rating-modal {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #f9f9f9;
  border: 1px solid #ccc;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 9999;
}

.degerlenedirilen-siparis {
  margin-bottom: 20px;
}

.degerlenedirilen-siparis p {
  margin: 5px 0;
}


/* Rating modal stilleri */
.rating-modal {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #f9f9f9;
  border: 1px solid #ccc;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 9999;
}

.rating-modal p {
  font-size: 18px;
  margin-bottom: 10px;
}

.rating-modal div {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.rating-modal label {
  display: flex;
  align-items: center;
  margin-right: 10px;
  cursor: pointer;
}

.rating-modal label input[type="radio"] {
  display: none;
}

.rating-modal .rating-box {
  display: flex;
}

.rating-modal label span {
  width: 30px;
  height: 30px;
  background-color: #ddd;
  border-radius: 50%;
  display: inline-block;
  margin-right: 5px;
  cursor: pointer;
}

.rating-modal label input[type="radio"]:checked + span {
  background-color: #2ecc71; /* Seçili yıldızı yeşil renge çevirin */
}

.rating-modal textarea {
  width: 100%;
  height: 100px;
  margin-bottom: 10px;
  resize: none;
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 5px;
}

.rating-modal button {
  cursor: pointer;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  color: #fff;
}

.rating-modal button:first-child {
  background-color: #3498db;
  margin-right: 10px;
}

.rating-modal button:last-child {
  background-color: #e74c3c;
}

.rating-modal button:first-child:hover,
.rating-modal button:last-child:hover {
  opacity: 0.8;
}
.photo-upload {
  margin-top: 20px;
}
.photo-preview {
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
  margin-top: 10px;
}
.photo-thumbnail {
  width: 100px;
  height: 100px;
  object-fit: cover;
  border: 1px solid #ccc;
  border-radius: 5px;
}

