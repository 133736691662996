@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap');

*{
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    font-family: 'Poppins', sans-serif;
}

  .header {
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: var(--whiteColorDeam);
    width: 100%;
    height: var(--headerHeight);
    z-index: 1000;
    padding: 1rem;
  }
  .header .logo {
    color: var(--blackColor);
    font-weight: 600;
    cursor: pointer;
    align-items: center;
    justify-content: center;
  }
  .header .logo .icon {
    font-size: 25px;
    color: var(--PrimaryColor);
    transform: translateY(5px);
  }
  @media screen and (max-width: 768px) {
    .header .navBar {
      background: var(--whiteColorDeam);
      position: absolute;
      height: -webkit-max-content;
      height: -moz-max-content;
      height: max-content;
      width: 80%;
      border-radius: 1rem;
      padding: 1rem;
      top: -700%;
      left: 50%;
      transform: translate(-50%);
      z-index: 2000;
      box-shadow: 0 2px 4px rgba(2, 15, 29, 0.904);
      transition: 0.3s ease-in-out;
    }
    .header .navBar .navLists {
      display: flex;
      flex-direction: column;
      justify-content: center;
      width: 100%;
      align-items: center;
      margin: auto;
      padding: 0.5rem 0;
    }
    .header .navBar .navLists .navItem {
      padding: 0.5rem 0;
    }
    .header .navBar .navLists .navItem .navLink {
      color: var(--textColor);
      font-size: 0.9rem;
      font-weight: 600;
    }
    .header .navBar .navLists .navItem .navLink:hover {
      color: var(--PrimaryColor);
    }
    .header .navBar .navLists .btn {
      margin-top: 1rem;
    }
    .header .navBar .navLists .btn a {
      font-weight: 600;
      color: var(--whiteColor);
    }
    .header .navBar .closeNavbar {
      position: absolute;
      top: 1rem;
      right: 1.5rem;
      color: var(--PrimaryColor);
    }
    .header .navBar .closeNavbar:hover .icon {
      color: var(--SecondaryColor);
    }
    .header .activeNavbar {
      top: 7rem;
    }
  }
  .header .toggleNavbar .icon {
    font-size: 25px;
    color: var(--PrimaryColor);
  }
  .header .toggleNavbar:hover.icon {
    color: var(--SecondaryColor);
  }
  
  .activeHeader {
    background: var(--projectCardColor);
    box-shadow: 0 2px 4px rgba(2, 15, 29, 0.904);
  }
  
  @media screen and (min-width: 769px) {
    .toggleNavbar, .closeNavbar {
      display: none;
    }
    .header {
      padding: 1.5rem 2rem;
    }
    .header .navBar .navLists {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: row;
    }
    .header .navBar .navLists .navItem .navLink {
      color: var(--textColor);
      padding: 0 0.45rem;
      font-size: 0.9rem;
      font-weight: 500;
    }
    .header .navBar .navLists .navItem .navLink:hover {
      color: var(--PrimaryColor);
    }
    .header .navBar .navLists .navItem .navLink .headerNumber {
      color: var(--primaryHover);
    }
    .header .navBar .navLists .btn {
      margin-left: 1rem;
    }
    .header .navBar .navLists .btn a {
      color: var(--whiteColor);
      font-weight: 500;
    }
  }
  @media screen and (min-width: 960px) {
    .header .navBar .navLists .navItem {
      padding: 0 0.7rem;
    }
  }
  @media screen and (min-width: 1024px) {
    .header {
      padding: 1rem 8.5rem;
    }
  }

  