@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@500;700&family=Spartan:wght@300;400;500&display=swap");
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  scroll-behavior: smooth;
  font-family: "Spartan", sans-serif;
  transition: 0.2s ease;
  /* font-family: 'Montserrat', sans-serif; */
}

:root {
  /* --PrimaryColor: hsl(225, 50%, 48%); */
  --PrimaryColor: hsl(0, 0%, 0%);
  --SecondaryColor: hsl(180, 17%, 95%);
  --colorOne: hsl(15, 74%, 70%);
  --colorTwo: hsl(28, 100%, 82%);
  --HoverColor: hsl(0, 0%, 9%);
  --whiteColor: hsl(0, 0%, 100%);
  --blackColor: hsl(0, 0%, 12%);
  --textColor: hsl(0, 0%, 0%);
  --borderColor: hsl(0, 0%, 83%);
  --whiteColorDeam: hsl(0, 0%, 93%);
  --greyBg: hsl(0, 0%, 96%);
  --greyText: rgb(0, 0, 0);
  --inputColor: hsl(330, 12%, 97%);
  --boxShadow: box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px;
  --transition: .4s ease-in-out;
}
.tanitim{
    overflow: auto;
}

body {
  background: var(--whiteColor);
}

a {
  text-decoration: none;
}

li {
  list-style: none;
  cursor: pointer;
}

.section {
  padding: 4rem 0 2rem;
}

.container {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

.icon {
  font-size: 1.3rem;
  cursor: pointer;
}

.flex {
  display: flex;
  align-items: center;
}

.grid {
  display: grid;
  align-items: center;
}

.none {
  display: none;
}

.btn {
  padding: 0.7rem 1.5rem;
  background: var(--PrimaryColor);
  border: none;
  outline: none;
  border-radius: 3rem;
  cursor: pointer;
  color: var(--whiteColor);
}

.btn a {
  color: var(--whiteColor);
  font-weight: 500;
}

.btn:hover {
  background: var(--HoverColor);
}

.btn:hover a {
  color: var(--whiteColor);
}

img, video {
  width: 100%;
  height: auto;
}

input {
  font-size: 100%;
  color: var(--greyText);
}

p {
  font-size: 13px;
  color: var(--greyText);
  line-height: 22px;
}

h4 {
  padding: 1rem 0;
  font-weight: 700;
  color: black;
}

h2 {
  font-size: 25px;
  padding: 1rem 0;
  color: var(--blackColor);
}

.navBar {
  display: flex;
  align-items: center;
  position: fixed;
  flex-direction: column;
  z-index: 1000;
  width: 100%;
}
.navBar .navBarOne, .navBar .navBarTwo {
  justify-content: space-between;
  padding: 1rem;
  width: 100%;
}
.navBar .navBarOne {
  background: var(--SecondaryColor);
}
.navBar .navBarOne .none {
  display: none;
}
.navBar .navBarOne .icon:hover {
  color: var(--PrimaryColor);
}
.navBar .navBarOne .atb {
  gap: 1rem;
}
.navBar .navBarOne .atb span {
  color: var(--blackColor);
  font-size: 13px;
  cursor: pointer;
}
.navBar .navBarOne .atb span:hover {
  color: var(--PrimaryColor);
  font-size: 700;
}
.navBar .navBarTwo {
  display: flex;
  align-items: center;
  justify-content: space-between !important;
  top: 3rem;
  padding: 1.5rem 1rem;
  position: fixed;
  transition: 0.3s ease-in-out;
}
.navBar .navBarTwo .logoDiv .Logo {
  cursor: pointer;
  width: 20%;
}
@media screen and (max-width: 768px) {
  .navBar .navBarTwo .navBarMenu {
    position: absolute;
    background: var(--SecondaryColor);
    padding: 1.5rem;
    border-radius: 10px;
    top: 5rem;
    right: -50%;
    width: 80%;
    transform: translateX(50%);
    z-index: 2000;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px;
    border: 3px solid var(--whiteColor);
    transition: 0.4s ease-in-out;
  }
  .navBar .navBarTwo .navBarMenu .menu {
    flex-direction: column;
    gap: 0.5rem;
    text-align: center;
  }
  .navBar .navBarTwo .navBarMenu .menu .listItem {
    padding: 0.5rem 0;
    cursor: pointer;
    color: var(--blackColor);
  }
  .navBar .navBarTwo .navBarMenu .menu .listItem:hover {
    color: var(--PrimaryColor);
    font-weight: 700;
  }
  .navBar .navBarTwo .navBarMenu .btnOne {
    display: block;
    justify-content: center;
    margin: auto;
    align-self: center;
    margin-top: 1rem;
  }
  .navBar .navBarTwo .btnTwo {
    display: none;
  }
}
.navBar .navBarTwo .showNavBar {
  right: 50%;
  transition: 0.5s ease-in-out;
}
.navBar .navBarTwo .toggleIcon .icon {
  font-size: 30px;
  color: var(--blackColor);
}
.navBar .activeHeader {
  background: var(--SecondaryColor);
  padding: 0.5rem 1rem;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px;
}

.home {
  padding-top: 10rem;
  text-align: center;
  gap: 2rem;
  flex-direction: column;
}
.home .mainText {
  color: var(--blackColor);
  font-weight: 700;
  font-size: 15px;
  line-height: 2.5rem;
}
.home .homeImages {
  width: 100%;
  margin: 1rem auto 0;
  justify-content: center;
  align-items: center;
  position: relative;
  border-radius: 5rem;
}
.home .homeImages .videoDiv {
  width: 100%;
}
.home .homeImages .videoDiv .video {
  border-radius: 10rem;
}
.home .homeImages .plane {
  position: absolute;
  width: 100%;
  top: -10%;
}

.search .sectionContainer {
  width: 100%;
  padding: 1.5rem;
  border: 2px solid var(--greyBg);
  border-radius: 16px;
  row-gap: 2rem;
}
.search .sectionContainer .btns {
  background: var(--SecondaryColor);
  padding: 0.5rem;
  border-radius: 1rem;
  flex-direction: column;
  width: 100%;
  margin: auto;
}
.search .sectionContainer .btns .singleBtn {
  padding: 0.8rem 1.5rem;
  font-size: 13px;
  border-radius: 3rem;
  width: 80%;
  text-align: center;
  cursor: pointer;
}
.search .sectionContainer .btns .singleBtn:hover {
  background: var(--PrimaryColor);
  color: var(--whiteColor);
  font-weight: 500;
  transition: 0.4s ease;
}
.search .sectionContainer .btns .active {
  background: var(--PrimaryColor);
  color: var(--whiteColor);
  font-weight: 500;
}
.search .sectionContainer .searchInputs {
  width: 100%;
  justify-content: center;
  flex-direction: column;
  gap: 1.5rem;
}
.search .sectionContainer .searchInputs .singleInput .iconDiv {
  padding: 16px 10px;
  border-radius: 3rem;
  background: var(--SecondaryColor);
  margin-right: 1rem;
}
.search .sectionContainer .searchInputs .singleInput .iconDiv .icon {
  color: var(--blackColor);
  opacity: 0.7;
}
.search .sectionContainer .searchInputs .singleInput .texts h4 {
  padding-top: 0;
  padding-bottom: 0.7rem;
  color: var(--blackColor);
  font-weight: 700;
}
.search .sectionContainer .searchInputs .singleInput .texts input {
  width: 95%;
  border: none;
  outline: none;
  background: transparent;
}
.search .sectionContainer .searchInputs .singleInput .texts input::-moz-placeholder {
  font-size: 12px;
  opacity: 0.5;
}
.search .sectionContainer .searchInputs .singleInput .texts input:-ms-input-placeholder {
  font-size: 12px;
  opacity: 0.5;
}
.search .sectionContainer .searchInputs .singleInput .texts input::placeholder {
  font-size: 12px;
  opacity: 0.5;
}
.search .sectionContainer .btnBlock {
  width: 100%;
  justify-content: center;
  gap: 0.5rem;
}

.support .sectionContainer .titlesDiv {
  text-align: center;
}
.support .sectionContainer .titlesDiv small {
  letter-spacing: 0.8rem;
  text-transform: uppercase;
  font-size: 13px;
  color: var(--blackColor);
  line-height: 1.5rem;
}
.support .sectionContainer .infoDiv {
  margin-top: 2rem;
  gap: 1.5rem;
}
.support .sectionContainer .infoDiv .textDiv {
  gap: 1.5rem;
}
.support .sectionContainer .infoDiv .textDiv .singleInfo .number {
  background: var(--PrimaryColor);
  padding: 5px 12px;
  width: 40px;
  border-radius: 3rem;
  text-align: center;
  font-size: 13px;
  font-style: 700;
  color: var(--whiteColor);
}
.support .sectionContainer .infoDiv .textDiv .singleInfo .colorOne {
  background: var(--colorOne);
}
.support .sectionContainer .infoDiv .textDiv .singleInfo .colorTwo {
  background: var(--colorTwo);
}
.support .sectionContainer .infoDiv .imgDiv {
  margin: auto;
 
}
.support .sectionContainer .infoDiv .imgDiv img {
  width: 100%;
  max-width: 500px;
  border-radius: 1rem;
}

.info {
  background: var(--SecondaryColor);
}
.info .infoContainer .titleDiv {
  flex-direction: column;
  text-align: center;
  gap: 1.5rem;
  justify-content: space-between;
}
.info .infoContainer .cardsDiv {
  gap: 1.5rem;
  margin-top: 3rem;
}
.info .infoContainer .cardsDiv .singleCard {
  background: var(--whiteColor);
  padding: 3rem 2rem;
  border-radius: 3rem;
  gap: 1rem;
  text-align: center;
  margin: auto;
  height: 320px;
  width: 90%;
  max-width: 250px;
}
.info .infoContainer .cardsDiv .singleCard .iconDiv {
  justify-content: center;
  margin: auto;
  background: var(--PrimaryColor);
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
  height: 55px;
  padding: 1rem;
  border-radius: 3rem;
}
.info .infoContainer .cardsDiv .singleCard .iconDiv .icon {
  color: var(--whiteColor);
}
.info .infoContainer .cardsDiv .singleCard .cardTitle {
  color: var(--blackColor);
  font-weight: 700;
}
.info .infoContainer .cardsDiv .singleCard .colorOne {
  background: var(--colorOne);
}
.info .infoContainer .cardsDiv .singleCard .colorTwo {
  background: var(--colorTwo);
}

.lounge .sectionContainer {
  gap: 1.5rem;
}
.lounge .sectionContainer .imgDiv {
  margin: auto;
}
.lounge .sectionContainer .imgDiv img {
  width: 100%;
  max-width: 320px;
}
.lounge .sectionContainer .textDiv .grids {
  margin-top: 1rem;
  gap: 1.5rem;
}
.lounge .sectionContainer .textDiv .grids .singleGrid .gridTitle {
  display: block;
  padding-bottom: 0.5rem;
  font-weight: 500;
  color: var(--blackColor);
}

.travelers .sectionContainer {
  text-align: center;
}
.travelers .sectionContainer .travelersContainer {
  margin-top: 1rem;
  gap: 1.5rem;
  height: 1000px;
  overflow: auto;
}

.travelers .sectionContainer .travelersContainer {
  margin-top: 1rem;
  gap: 1.5rem;
  height: 1000px;
  overflow: auto;
}

/* Scroll çubuğu */
.travelers .sectionContainer .travelersContainer::-webkit-scrollbar {
  width: 4px; /* Scroll çubuğunun genişliği */
}

/* Scroll çubuğu parçası */
.travelers .sectionContainer .travelersContainer::-webkit-scrollbar-thumb {
  background-color: #8b8a8a; /* Scroll çubuğu parçasının rengi */
  border-radius: 5px; /* Scroll çubuğu parçasının köşe yarıçapı */
}

/* Scroll çubuğu boşluğu */
.travelers .sectionContainer .travelersContainer::-webkit-scrollbar-track {
  background-color: #f1f1f1; /* Scroll çubuğu boşluğunun rengi */
  border-radius: 5px; /* Scroll çubuğu boşluğunun köşe yarıçapı */
}

/* Aktif scroll çubuğu parçası (kaydırma işlemi sırasında) */
.travelers .sectionContainer .travelersContainer::-webkit-scrollbar-thumb:hover {
  background-color: #555; /* Aktif scroll çubuğu parçasının hover rengi */
}

.travelers .sectionContainer .travelersContainer .singleTraveler {
  position: relative;
  height: 280px;
  width: 85%;
  max-width: 600px;
  overflow: hidden;
  border-radius: 0.5rem;
  gap: 0.5rem;
  margin: auto;
  height: 300px;
}
.travelers .sectionContainer .travelersContainer .singleTraveler .destinationImage, .travelers .sectionContainer .travelersContainer .singleTraveler .travelerImage, .travelers .sectionContainer .travelersContainer .singleTraveler .travelerName {
  position: absolute;
}
.travelers .sectionContainer .travelersContainer .singleTraveler .destinationImage {
  height: 100%;
  width: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  -o-object-fit: cover;
     object-fit: cover;
}
.travelers .sectionContainer .travelersContainer .singleTraveler .travelerImage {
  height: 50px;
  width: 50px;
  -o-object-fit: cover;
     object-fit: cover;
  border-radius: 50%;
  bottom: 30px;
  left: 40%;
  transform: translateX(-50%);
  border: 5px solid rgba(255, 255, 255, 0.637);
  transition: var(--transition);
  -webkit-animation: identifier 2.3s infinite;
          animation: identifier 2.3s infinite;
}
@-webkit-keyframes identifier {
  0% {
    transform: translateY(0px);
  }
  50% {
    transform: translateY(8px);
  }
  100% {
    transform: translateY(0px);
  }
}
@keyframes identifier {
  0% {
    transform: translateY(0px);
  }
  50% {
    transform: translateY(8px);
  }
  100% {
    transform: translateY(0px);
  }
}
.travelers .sectionContainer .travelersContainer .singleTraveler .travelerName {
  bottom: -100%;
  width: 100%;
  height: -webkit-max-content;
  height: -moz-max-content;
  height: max-content;
  background: var(--whiteColor);
  padding: 1.5rem 1rem;
  transition: var(--transition);
  -webkit-animation: yeaMove 2.3s infinite;
          animation: yeaMove 2.3s infinite;
}
.travelers .sectionContainer .travelersContainer .singleTraveler .travelerName span {
  display: block;
  color: var(--blackColor);
  font-weight: 700;
}
.travelers .sectionContainer .travelersContainer .singleTraveler .travelerName p {
  color: var(--PrimaryColor);
  font-style: italic;
}
@-webkit-keyframes yeaMove {
  0% {
    border-radius: 0rem;
  }
  50% {
    border-radius: 2rem;
  }
  100% {
    border-radius: 0rem;
  }
}
@keyframes yeaMove {
  0% {
    border-radius: 0rem;
  }
  50% {
    border-radius: 2rem;
  }
  100% {
    border-radius: 0rem;
  }
}
.travelers .sectionContainer .travelersContainer .singleTraveler:hover .travelerName {
  bottom: 0;
  transition: 1s ease-in-out;
}
.travelers .sectionContainer .travelersContainer .singleTraveler:hover .travelerImage {
  bottom: 110px;
  transition: 1s ease-in-out;
}

.subscribe {
  background: var(--SecondaryColor);
}
.subscribe .sectionContainer {
  text-align: center;
}
.subscribe .sectionContainer .inputDiv {
  margin-top: 1rem;
  flex-direction: column;
  background: var(--whiteColor);
  border-radius: 1rem;
}
.subscribe .sectionContainer .inputDiv input {
  border: none;
  outline: none;
  background: transparent;
  padding: 1rem 0.5rem;
}
.subscribe .sectionContainer .inputDiv input::-moz-placeholder {
  font-size: 13px;
  opacity: 0.7;
}
.subscribe .sectionContainer .inputDiv input:-ms-input-placeholder {
  font-size: 13px;
  opacity: 0.7;
}
.subscribe .sectionContainer .inputDiv input::placeholder {
  font-size: 13px;
  opacity: 0.7;
}
.subscribe .sectionContainer .inputDiv .btn {
  width: 100%;
}

.footer {
  background: var(--SecondaryColor);
}
.footer .sectionContainer {
  gap: 2.5rem;
}
.footer .sectionContainer .gridOne {
  gap: 1rem;
  justify-content: center;
  margin: auto;
}
.footer .sectionContainer .gridOne .logoDiv .Logo {
  width: 40%;
}
.footer .sectionContainer .gridOne .socialIcons {
  margin-top: 1rem;
  gap: 1rem;
}
.footer .sectionContainer .gridOne .socialIcons .icon {
  height: 50px;
  width: 30px;
  border-radius: 10rem;
  border: 1px solid var(--borderColor);
  padding: 0.4rem;
  color: var(--greyText);
}
.footer .sectionContainer .gridOne .socialIcons .icon:hover {
  background: var(--PrimaryColor);
  transform: translateY(-7px);
  transition: var(--transition);
  border: 1px solid var(--PrimaryColor);
  color: var(--whiteColor);
}
.footer .sectionContainer .footerLinks {
  display: grid;
}
.footer .sectionContainer .footerLinks .linkTitle {
  color: var(--blackColor);
  font-weight: 500;
  display: block;
  padding-bottom: 1rem;
}
.footer .sectionContainer .footerLinks li a {
  display: block;
  color: var(--greyText);
  padding: 0.3rem 0;
  transition: var(--transition);
}
.footer .sectionContainer .footerLinks li a:hover {
  transform: translateX(7px);
  color: var(--PrimaryColor);
}
.footer .copyrightDiv {
  justify-content: center;
  margin-top: 1.5rem;
  padding: 1rem;
  gap: 1rem;
  border-top: 1px solid var(--greyText);
  text-align: center;
}

@media screen and (min-width: 480px) {
  .home {
    padding-top: 12rem;
  }
  .search .sectionContainer .searchInputs {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }
  .travelers .sectionContainer .travelersContainer {
    grid-template-columns: repeat(2, 1fr);
  }
  .subscribe .sectionContainer {
    max-width: 80%;
    margin: auto;
  }
  .subscribe .sectionContainer .inputDiv {
    flex-direction: row;
    padding: 0 0.5rem;
    border-radius: 3rem;
  }
  .footer .sectionContainer {
    grid-template-columns: repeat(2, 1fr);
  }
  .footer .sectionContainer .footerLinks {
    justify-content: center;
  }
}
@media screen and (min-width: 570px) {
  .home .mainText {
    width: 80%;
  }
  .home .homeImages .video {
    border-radius: 1rem;
  }
  .home .homeImages .plane {
    position: absolute;
    width: 95%;
    top: -8%;
  }
  .search .sectionContainer .btns {
    border-radius: 3rem;
    flex-direction: row;
  }
  .search .sectionContainer .searchInputs {
    grid-template-columns: repeat(2, 1fr);
  }
  .support .sectionContainer .infoDiv .textDiv {
    margin-top: 2rem;
    grid-template-columns: repeat(2, 1fr);
  }
  .info .infoContainer .titleDiv {
    flex-direction: row;
  }
  .info .infoContainer .titleDiv h2 {
    text-align: left;
    max-width: 500px;
  }
  .info .infoContainer .cardsDiv {
    grid-template-columns: repeat(2, 1fr);
  }
  .lounge .sectionContainer .textDiv .grids {
    grid-template-columns: repeat(2, 1fr);
  }
  .subscribe .sectionContainer {
    max-width: 80%;
    margin: auto;
  }
  .subscribe .sectionContainer .inputDiv {
    flex-direction: row;
    padding: 0 0.5rem;
    border-radius: 3rem;
    justify-content: space-between;
  }
  .subscribe .sectionContainer .inputDiv .btn {
    max-width: -webkit-max-content;
    max-width: -moz-max-content;
    max-width: max-content;
  }
  .footer .sectionContainer {
    padding-top: 2rem;
    align-items: flex-start;
    grid-template-columns: repeat(3, 1fr);
  }
}
@media screen and (min-width: 680px) {
  .home .mainText {
    width: 80%;
  }
  .home .homeImages .videoDiv {
    width: 100%;
  }
  .home .homeImages .videoDiv .video {
    height: 100%;
    width: 100%;
    border-radius: 1rem;
  }
  .home .homeImages .plane {
    position: absolute;
    width: 95%;
    top: -8%;
  }
  .search .sectionContainer .btns {
    border-radius: 3rem;
    flex-direction: row;
  }
  .search .sectionContainer .searchInputs {
    grid-template-columns: repeat(3, 1fr);
  }
  .footer .sectionContainer {
    padding-top: 2rem;
    align-items: flex-start;
    grid-template-columns: repeat(4, 1fr);
  }
}
@media screen and (min-width: 768px) {
  .navBar {
    align-items: center;
  }
  .navBar .navBarOne .none {
    display: flex;
    gap: 1.5rem;
  }
  .navBar .navBarOne .none li {
    font-size: 13px;
    gap: 0.5rem;
    color: var(--textColor);
  }
  .navBar .navBarOne .none li:hover {
    color: var(--PrimaryColor);
    text-decoration: underline;
  }
  .navBar .navBarTwo {
    justify-content: space-between;
    align-items: center;
  }
  .navBar .navBarTwo .logoDiv .Logo {
    max-width: 40%;
  }
  .navBar .navBarTwo .menu {
    flex-direction: row;
    gap: 0.5rem;
  }
  .navBar .navBarTwo .menu .listItem {
    padding: 0 0.5rem;
    font-size: 14px;
  }
  .navBar .navBarTwo .menu .listItem {
    padding: 0 0.5rem;
    font-size: 14px;
    cursor: pointer;
    color: var(--textColor);
    font-weight: 700;
  }
  .navBar .navBarTwo .menu .listItem:hover {
    color: var(--PrimaryColor);
    font-weight: 700;
  }
  .navBar .navBarTwo .btnOne, .navBar .navBarTwo .toggleIcon {
    display: none;
  }
  .navBar .navBarTwo .btnTwo {
    display: block;
  }
  .search .sectionContainer .btns {
    width: 70%;
    border-radius: 3rem;
    flex-direction: row;
  }
  .search .sectionContainer .searchInputs {
    grid-template-columns: repeat(4, 1fr);
  }
  .search .sectionContainer .searchInputs .singleInput .texts input {
    width: 100%;
  }
  .support .sectionContainer .infoDiv {
    grid-template-columns: repeat(2, 1fr);
    gap: 2rem;
    margin-top: 5rem;
  }
  .support .sectionContainer .infoDiv .textDiv {
    margin-top: 0rem;
    grid-template-columns: repeat(1, 1fr);
  }
  .info .infoContainer {
    padding: 0 6rem;
  }
  .info .infoContainer .titleDiv {
    width: 90%;
    margin: auto;
    align-items: center;
  }
  .info .infoContainer .cardsDiv {
    grid-template-columns: repeat(3, 1fr);
  }
  .info .infoContainer .cardsDiv .singleCard {
    max-width: 260px;
    padding: 3rem;
  }
  .lounge .sectionContainer {
    grid-template-columns: repeat(2, 1fr);
  }
  .lounge .sectionContainer .textDiv {
    grid-area: 1;
  }
  .lounge .sectionContainer .textDiv .grids {
    grid-template-columns: repeat(2, 1fr);
  }
  .travelers .sectionContainer .travelersContainer {
    grid-template-columns: repeat(3, 1fr);
  }
}
@media screen and (min-width: 960px) {
  .home {
    padding-top: 13rem;
  }
  .home .mainText {
    width: 70%;
    padding-bottom: 1.5rem;
    font-size: 22px;
    line-height: 4rem;
  }
  .search .sectionContainer .searchInputs {
    grid-template-columns: repeat(5, 1fr);
  }
  .travelers .sectionContainer .travelersContainer {
    margin: 3rem 0;
    grid-template-columns: repeat(4, 1fr);
  }
  .subscribe .sectionContainer {
    max-width: 70%;
  }
}
@media screen and (min-width: 1024px) {
  p {
    font-size: 15px;
    line-height: 25px;
  }
  h4 {
    font-size: 20px;
  }
  h2 {
    font-size: 27px;
  }
  .container {
    width: 75%;
    margin: auto;
  }
  .section {
    padding: 6rem 0 4rem;
  }
  .navBar .navBarOne, .navBar .navBarTwo {
    padding: 1.5rem 5rem;
  }
  .navBar .activeHeader {
    padding: 0 5rem;
  }
  .home .mainText {
    width: 60%;
    padding-bottom: 1.5rem;
    font-size: 25px;
    line-height: 4rem;
  }
  .home .homeImages .videoDiv {
    width: 100%;
    height: auto;
  }
  .home .homeImages .videoDiv .video {
    height: 100%;
    width: 100%;
    -o-object-fit: cover;
       object-fit: cover;
    border-radius: 15rem;
  }
  .home .homeImages .plane {
    position: absolute;
    width: 100%;
    top: -10%;
  }
  .search .sectionContainer .btns {
    width: 50%;
  }
}
@media screen and (min-width: 1106px) {
  .home .homeImages .videoDiv {
    width: 90%;
  }
  .home .homeImages .videoDiv .video {
    height: 100%;
    width: 100%;
    -o-object-fit: cover;
       object-fit: cover;
    border-radius: 0.2rem;
  }
  .home .homeImages .plane {
    position: absolute;
    width: 100%;
    top: -10%;
  }
  .subscribe .sectionContainer {
    max-width: 60%;
  }
}
@media screen and (min-width: 1332px) {
  .navBar .navBarTwo .menu {
    gap: 1rem;
  }
  .navBar .navBarTwo .menu .listItem {
    padding: 0 1rem;
    font-size: 14px;
  }
  .navBar .navBarTwo .btnOne, .navBar .navBarTwo .toggleIcon {
    display: none;
  }
  .navBar .navBarTwo .btnTwo {
    display: block;
  }
  .home .homeImages .plane {
    position: absolute;
    width: 100%;
    top: -10%;
  }
  .support .sectionContainer .infoDiv {
    gap: 1rem;
  }
  .support .sectionContainer .infoDiv .textDiv .singleInfo p {
    max-width: 500px;
  }
  .subscribe .sectionContainer {
    max-width: 50%;
  }
}
@media screen and (min-width: 1440px) {
  .home .homeImages .plane {
    position: absolute;
    width: 90%;
    top: -30%;
  }
}
::-moz-selection {
  background: var(--PrimaryColor);
  color: var(--whiteColor);
}
::selection {
  background: var(--PrimaryColor);
  color: var(--whiteColor);
}

::-webkit-scrollbar {
  width: 10px;
  background: var(--greyBg);
}

::-webkit-scrollbar-thumb {
  background: var(--PrimaryColor);
  border-radius: 10px;
}

.contact {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
}

.contact-info {
  flex: 1;
  margin-right: 20px;
}
.contact-info h3 {
 margin-bottom:20px ;
}

.contact-map {
  flex: 1;
  height: 300px; 
  
  text-align: center;
  width: 100%;
}

@media (max-width: 768px) {
  .contact {
    flex-direction: column;
  }

  .contact-info {
    margin-bottom: 20px;
  }
}

.selected {
  font-weight: bold;
  
}

.product-detail-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 5%;
}
.ask-price-button {
  padding: 10px 20px;
  background-color: #25D366;
  color: #0d1232;
  border: none;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;
  margin-top: 4%;
  font-weight: bold;
}

.ask-price-button:hover {
  background-color:#128C7E;
  color: white;
}

.product-image-container {
  flex: 1;
  max-width: 50%;
  padding-right: 20px;
  margin-right: 15%;
}

.product-image-container img {
  max-width: 100%;
  height: auto;
  margin-left: 15%;
 
  margin-top: 25%;
  margin-bottom: 15%;
}

.product-info-container {
  flex: 1;
  max-width: 50%;
}

@media (max-width: 768px) {
  .product-detail-container {
    flex-direction: column;
    align-items: flex-start;
  }

  .product-image-container,
  .product-info-container {
    max-width: 100%;
   
  }

  .product-info-container{
    margin-left: 10%;
  }

  .product-image-container {
    padding-right: 0;
    padding-bottom: 20px;
  }
}
.loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
}

.spinner {
  border: 4px solid #f3f3f3;
  border-top: 4px solid #e7c896;
  border-radius: 50%;
  width: 100px;
  height: 100px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}


.product-slider-container {
  position: relative;
}

.thumbnail-images {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 10px;
}

.thumbnail {
  width: 100px;
  height: 100px;
  margin-right: 5px;
  cursor: pointer;
  position: relative;
  
}

.thumbnail img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.thumbnail.active:after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border: 2px solid #000;
  height: max-content;
  box-sizing: border-box; /* Kutu modelini sınır kutusu olarak ayarla */
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
}



.modal-close {
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: transparent;
  border: none;
  font-size: 24px;
  color: white;
  cursor: pointer;
}

.modal-image-container {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
 
}

.modal-image {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
  transition: transform 0.3s ease;
  margin-bottom: 20%;
 
}

.modal-image:hover {
  transform: scale(1.2);
  margin-bottom: 20%;
}

.related-products-container {
  margin-top: 20px;
}

.related-products-container h3 {
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 10px;
}

.related-products-grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 20px;
}

.related-product {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
  margin-bottom: 50px;
}

.related-product img {
  max-width: 100%;
  height: auto;

  width: 400px;
  height: 300px;
}

.related-product img:hover {
  max-width: 100%;
  height: auto;
 

  width: 450px;
  height: 320px;
}

.related-product-details {
  margin-top: 10px;
  text-align: center;
}

.related-product-details h4 {
  font-size: 16px;
  margin-bottom: 5px;
}

.related-product-details a {
  text-decoration: none;
  color: #000;
  font-size: 14px;
}

.related-product-details a:hover {
  text-decoration: underline;
}


.gemstone-options {
  display: flex;
  overflow-x: auto; /* Allow horizontal scrolling when gemstones overflow */
  gap: 10px;
  padding: 10px 0;
  width: '50%';
  max-width: 600px;
  /* Optional: Add a max-width to limit the width of the scrollable container */
  /* max-width: 400px; */
}

.gemstone-option {
  flex-shrink: 0; /* Prevent gemstone images from shrinking */
}

.gemstone-option img {
  width: 100px;
  height: 100px;
  border: 2px solid #ccc;
  cursor: pointer;
  transition: border-color 0.2s ease;
}

.gemstone-option.active img {
  border-color: #000000;
  border: 2px solid black
}

/* Add this CSS code in your main.css or any appropriate CSS file */

.gemstone-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.gemstone-modal-content {
  position: relative;
  max-width: 90%;
  max-height: 90%;
}

.gemstone-modal-content img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  border: 2px solid #fff;
}

.modal-close {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  color: #fff;
  cursor: pointer;
  font-size: 25px;
}


.gemstone-modal-title {
  position: absolute;
  top: 10px;
  left: 50%;
  transform: translateX(-50%);
  background-color: #fff;
  color: #000;
  padding: 5px 10px;
  border-radius: 5px;
  font-size: 16px;
}
/* Customize the scrollbar appearance */
.gemstone-options::-webkit-scrollbar {
  width: 5px;
  height: 15px;
}

.gemstone-options::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 4px;
}

.gemstone-options::-webkit-scrollbar-thumb {
  background: #292929;
  border-radius: 20px;
}

.gemstone-options::-webkit-scrollbar-thumb:hover {
  background: #555;
}

/* Butonlar için temel stil ayarları */
.app-buttons {
    display: flex;
    justify-content: center;
    margin-top: 20px;
  }
  
  .app-button {
    margin: 0 10px;
    padding: 10px 20px;
    background-color: #fff839;
    color: rgb(5, 5, 5);
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .app-button:hover {
    background-color: #000000;
    color: white;
  }
  .privacy-page {
    padding: 100px;

   
  }
  
  .privacy-heading {
    font-size: 24px;
    margin-bottom: 20px;
    margin-top: 4%;
    margin-top: 200px;
  }
  
  .privacy-content {
    font-size: 16px;
    line-height: 1.6;
   
  }
  
  .privacy-link {
    color: #007bff;
    text-decoration: underline;
  }
  
  /* Responsive tasarım */
  @media (max-width: 768px) {
    .privacy-page {
      padding: 10px;
    }
  }
  